<template>
    <el-container>
        <el-header style="min-width:1500px" class="flex flex-v-center bg-white fixed flex-h-center header">
            <el-menu ref="topNav" text-color="#303030" active-text-color="#E6A23C" :default-active="active" router
                class="el-menu-demo" mode="horizontal">
                <el-menu-item index="/" class="menu">
                    <el-image class="logo" src="/web/image/logo.png" fit="cover"></el-image>
                </el-menu-item>
                <el-menu-item index="/main" class="menu bold m-hover">{{ $t('首页') }}</el-menu-item>
                <el-submenu :class="{'active':active == '/main/pte'}" v-if="$store.state.mode == 'PTE'" index="/main/pte"  class="menu0 bold  m-hover">
                    <template  slot="title">{{ $t('PTE练习') }}</template>
                    <div class="flex padding flex-h-center" style="width:100vw;">
                        <div class="margin-x" v-for="(sort, index) in $store.getters.getSorts(0)" :key="index">
                            <h3 style="margin-left:6px;">{{ sort.title }}</h3>
                            <div class="mt1 margin-ts pointer ul-hover" v-for="(type, index1) in sort.types"
                                :key="index1" @click="to('/main/pte?tag=' + type.tag)">{{ type.title }} <span class="st"
                                    v-if="type.ai_flag">{{ $t('AI评分')
                                    }}</span></div>
                        </div>
                        <div class="margin-x sort4">
                            <h4 style="margin-left:6px;">More</h4>
                            <div @click="walkman()" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE随身听') }}</div>
                            <div @click="to('/main/download')" class="mt1 margin-ts pointer ul-hover">{{ $t('题库一键导出 PDF') }}
                            </div>
                            <div @click="to('/main/wordbook')" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE词库') }}
                            </div>
                            <div @click="to('/main/mocks')" class="mt1 margin-ts pointer ul-hover">{{ $t('PTE模考') }}
                            </div>
                        </div>
                    </div>
                </el-submenu>

                <!-- <el-menu-item index="/main/ccl" class="menu">CCL练习</el-menu-item> -->
                <!-- <el-menu-item index="/course" class="menu bold  m-hover">PTE课程</el-menu-item> -->
                <el-submenu :class="{'active':active == '/main/media'}" v-if="$store.state.mode == 'PTE'" index="/course" class="menu0 bold">
                    <template slot="title">{{ $t('PTE课程') }}</template>
                    <el-menu-item :key="index" v-for="(course, index) in $store.getters.getCourses('PTE')"
                        :index="'/main/media?tag=course&id=' + course.id" class="menu bold m-hover"
                        style="font-size: 14px !important;">{{
                            course.title }}</el-menu-item>
                </el-submenu>




                <el-submenu v-if="$store.state.mode == 'CCL'" index="/main/ccl" class="menu0 bold m-hover">
                    <template slot="title" class="m-hover">{{ $t('CCL练习') }}</template>
                    <div class="flex padding flex-h-center" style="width:100vw;">
                        <div class="margin-lx" v-for="index in 4" :key="index">
                            <div :class="{ 'margin-t': index1 > 0 }" class="mt1  pointer ul-hover"
                                v-for="(type, index1) in $store.getters.getTypes(1).slice((index - 1) * 3, index * 3)"
                                :key="type.id" @click="to('/main/ccl?tag=' + type.tag)">{{ type.title.replace("-", "")
                                }}
                            </div>
                        </div>
                    </div>
                </el-submenu>


                <el-submenu v-if="$store.state.mode == 'CCL'" index="/course" class="menu0 bold">
                    <template slot="title">{{ $t('CCL课程') }}</template>
                    <el-menu-item :key="index" v-for="(course, index) in $store.getters.getCourses('CCL')"
                        :index="'/main/media?tag=course&id=' + course.id" class="menu bold m-hover"
                        style="font-size: 14px !important;">{{
                            course.title }}</el-menu-item>
                </el-submenu>

                <el-menu-item index="/main/article" class="menu bold  m-hover">{{ $t('备考入门') }}</el-menu-item>

            </el-menu>
            <search-input></search-input>

            <el-menu :default-active="active" text-color="#303030" active-text-color="#E6A23C" router mode="horizontal">

                <!-- <el-menu-item class="flex flex-v-center">
                <el-tag class="flex flex-v-center radius-l">
                    <el-image style="height: 20px;"
                        src="https://upload.fireflyau.com/web_gwpte/pte/pte-index/discount.png"></el-image>
                    <span>考试报名优惠价 ￥1880 + 送VIP会员</span>
                </el-tag>
            </el-menu-item> -->

                <el-menu-item index="/main/certificate" class="flex flex-v-center"
                    v-if="$store.getters.getCertificate() == null">
                    <el-tag class="flex flex-v-center radius-l" type="primary">
                        <el-image style="height: 24px;width:24px ;" src="/web/image/vip.png?v=1"
                            fit="scale-down"></el-image>
                        <span>{{ $t('海澳学生认证') }}</span>
                    </el-tag>
                </el-menu-item>


                <el-menu-item v-if="$store.getters.getRole() == 0" index="/main/vip" class="flex flex-v-center">
                    <div class="flex flex-v-center bold  m-hover">
                        <el-image style="height: 24px; width:24px ;" fit="scale-down"
                            src="/web/image/vip1.png"></el-image>
                        <span>{{ $t('开通VIP') }}</span>
                    </div>
                </el-menu-item>
            </el-menu>



            <el-popover placement="bottom" :width="20" trigger="hover">

                <el-menu text-color="#303030" active-text-color="#E6A23C" router>
                    <el-menu-item v-if="$store.state.mode == 'PTE'"  index="/main/mine" class="bold center">
                        {{ $t('个人中心') }}
                    </el-menu-item>

                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/personal" class="bold center">
                        {{ $t('个人信息') }}
                    </el-menu-item>

                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/certificate" class="bold center">
                        {{ $t('学生认证') }}
                    </el-menu-item>

                    <el-menu-item @click="exit()" class="bold center">
                        {{ $t('退出登录') }}
                    </el-menu-item>
                </el-menu>


                <div v-if="$store.state.user" class="margin-l" slot="reference" style="position:relative;">

                    <el-image v-if="$store.getters.getRole() == 1" src="/web/image/vip1.png"
                        style="width: 30px;height: auto; position: absolute;z-index: 10;top:-15px;right:-10px;transform: rotate(45deg);">
                    </el-image>


                    <div style="position: absolute;z-index: 10;bottom:5px;left:45px;display: flex;">
                        <el-image v-if="$store.state.user.certificate.status == 1" style="width: 25px;height: auto;"
                            src="/web/image/pte.png"></el-image>
                        <el-image v-if="$store.state.user.ccl_certificate.status == 1"
                            :style="{ 'margin-left': $store.state.user.certificate.status == 1 ? '-5px' : 0 }"
                            style="width: 25px;height: auto;" src="/web/image/ccl.jpg"></el-image>
                    </div>



                    <el-image style="width:50px; height: 50px;" fit="scale-down" class="radius-c"
                        :src="$host + $store.state.user.avatar || '/web/image/logo0.png'">
                    </el-image>
                </div>
            </el-popover>


            <div v-if="!$store.state.isTw">
                <span class="tag pointer" @click="setLang('')" v-if="$store.state.lang == 'tw'"
                    style="margin-left: 50px;">简体</span>
                <span class="tag pointer" @click="setLang('tw')" v-else style="margin-left: 50px;">{{ $t('繁体', 'tw')
                    }}</span>
            </div>
            <!-- 
            <span class="tag pointer" style="margin-left: 30px;">{{ $store.state.mode == 'CCL' ? '切换PTE账户' : '切换CCL账户'
            }}</span> -->
        </el-header>


        <el-container class="main" :class="{ 'full': full }">

            <el-aside width="200px" style="position:fixed;top:100px; height: calc(100vh - 120px); overflow: scroll;"
                v-if="aside">
                <el-menu text-color="#303030" active-text-color="#E6A23C" router :default-active="active">
                    <el-menu-item index="/main" class="bold">{{ $t('首页') }}</el-menu-item>
                    <el-submenu v-if="$store.state.mode == 'PTE'" index="/main/mine" class="bold">
                        <template slot="title">
                            <el-menu-item style="padding-left: 0;" index="/main/mine" class="bold">{{ $t('个人中心') }}</el-menu-item>
                        </template>
                        <el-menu-item index="/main/plan" class="bold">{{ $t('我的计划') }}</el-menu-item>
                        <el-menu-item index="/main/favorites" class="bold">{{ $t('我的收藏') }}</el-menu-item>
                        <el-menu-item index="/main/diy?tag=mine" class="bold">{{ $t('我的模板') }}</el-menu-item>
                        <el-menu-item index="/main/personal" class="bold">{{ $t('个人信息') }}</el-menu-item>
                        
                    </el-submenu>
                    <el-menu-item v-if="$store.state.mode == 'PTE'" index="/main/pte?tag=prediction" class="bold">
                        {{ $t('PTE预测练习') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'PTE'" index="/main/practise" class="bold">
                        {{ $t('PTE机经练习') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/practise?tag=ccl" class="bold">{{
                        $t('CCL练习') }}</el-menu-item>


                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/ccl?tag=mock" class="bold">{{
                        $t('CCL模考')
                    }}</el-menu-item>

                    <el-menu-item @click="walkman()" class="bold">{{ $store.state.mode }}{{ $t('随身听') }}</el-menu-item>

                   

                    <el-menu-item index="/main/wordbook" class="bold">{{ $store.state.mode }}{{ $t('词库')
                        }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/personal" class="bold">{{ $t('个人信息') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/plan" class="bold">{{ $t('今日计划') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'CCL'" index="/main/favorites" class="bold">{{ $t('我的收藏') }}</el-menu-item>
                   
                    <el-menu-item v-if="$store.state.mode == 'PTE'" index="/main/mocks" class="bold">
                        {{ $t('PTE模考') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'PTE'" index="/main/immersive" class="bold">
                            {{ $t('沉浸式练习') }}</el-menu-item>
                    <el-menu-item v-if="$store.state.mode == 'PTE'" index="/main/diy" class="bold">
                        {{ $t('DIY模板') }}</el-menu-item>



                    <!-- <el-menu-item index="/main/plan" class="bold">{{ $t('我的计划') }}</el-menu-item> -->
                    
                    <el-menu-item index="/main/documents" class="bold">{{ $t('资料下载') }}</el-menu-item>
                    <el-menu-item index="/main/download" class="bold">
                        {{ $t('机经一键导出 PDF') }}</el-menu-item>
                    
                    <el-menu-item index="/main/certificate" class="bold">{{ $store.state.mode }}{{ $t('学生认证') }}</el-menu-item>
                    <el-menu-item index="/main/faq" class="bold">{{ $t('常见问题') }}</el-menu-item>
                    <!-- <el-menu-item index="/media?tag=article&id=1">{{$t('联系我们')}}</el-menu-item> -->
                    <el-menu-item index="" class="bold" @click="exit">{{ $t('退出登录') }}</el-menu-item>
                </el-menu>
            </el-aside>

            <el-main :style="{ 'margin-left': aside ? '200px' : '0' }">
                <keep-alive :include="include">
                    <router-view class="main-box"
                        :class="{ 'bg-withe': (this.$route.path != '/main/media' && this.$route.path != '/main/article') }"></router-view>
                </keep-alive>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import searchInput from '../../components/SearchInput.vue';
export default {
    components: {
        searchInput
    },
    data: function () {
        return {
            include: ["search", "immersive", "immersive-list", "immersive-record"],
            active: "/",
            aside: true,
            full: false,
            search: "",
        }
    },

    watch: {
        $route(route) {
            if (route.path == "/main/pte" || route.path == "/main/vocabulary" || route.path == "/main/article" || route.path == "/main/media") {
                this.aside = false;
            } else {
                this.aside = true;
            }

            if (route.path == "/main/pte") {
                this.full = true;
            } else {
                this.full = false;
            }
            if (route.path != '/main/plan/add') {
                this.active = route.path;
            }
        }
    },

    created() {

        this.active = this.$route.path
        if (this.active == "/main/pte") {
            this.aside = false;
        }

        this.$bus.$on("toggle_menu", () => {
            this.$nextTick(() => {
                if (this.$refs.topNav) {
                    this.$refs.topNav.close("/main/pte");
                    this.$refs.topNav.close("/main/ccl");
                }
            })
        })

        this.$bus.$on("aside", (flag) => {
            this.aside = flag == undefined ? !this.aside : flag;
        })

        this.$bus.$on("full", (flag) => {
            this.full = flag == undefined ? !this.aside : flag;
        })
    },
    methods: {
        setLang(lang) {
            this.$store.commit("setLang", lang)
        },
        exit() {
            this.$store.commit("setUser", null);
            window.location.href = "/";
        },
        to(url) {
            if (url.indexOf("/main/ccl") != -1) {
                this.$store.commit("setMode", "CCL");
            } else if (url.indexOf("/main/pte") != -1) {
                this.$store.commit("setMode", "PTE");
            }
            this.$refs.topNav.close("/main");
            this.$router.replace(url);
        },

        walkman() {
            window.open("https://www.ptefighter.com/walkman")
        },
    },
};
</script>

<style scoped>

.active>>>.el-submenu__title{
    color: #E6A23C !important;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-thickness: 2px;
}

.logo {
    width: 100px;
}


.tag {
    color: #f66;
    line-height: 20px;
    padding: 5px 15px;
    display: inline-block;
    border-radius: 15px;
    border: 1px solid #f66;
}




.main-box {
    min-width: 950px;
    margin: 0 20px;
    padding: 20px;
}

.main-box.bg-withe {
    background-color: #ffffff;
    border-radius: 10px;
}


.main {
    margin: 100px 8% 0;
    position: relative;
}

.main.full {
    margin: 100px 0 0;
}



.el-link.el-link--default:hover {
    color: #ffffff;
}

.el-link.el-link--default:after {
    border-color: #ffffff;
}

.el-divider {
    background-color: #606266;
}



.menu {
    padding: 0;
    margin: 10px 20px;
}

.menu0 {
    padding: 0;
    margin: 10px 0;
}
</style>
